import { Row, Col, Modal, Button, message } from "antd";
import { useState } from "react";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

import endpoints from "../../utils/endpoints.js";

export default function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleDelete() {
    setIsButtonLoading(true);

    axios
      .post(endpoints.room.delete, {
        data,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        message.success("Patrocinador foi apagado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  return (
    <Modal
      title={`Apagar sala`}
      open={open}
      onCancel={close}
      footer={[
        <Button danger icon={<DeleteOutlined />} size="large" type="primary" onClick={handleDelete} loading={isButtonLoading}>
          Apagar
        </Button>,
        <Button disabled={isButtonLoading} size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <p>Tem a certeza que quer apagar a sala:</p>
          <p className="font-bold text-lg mt-2">{data.name}</p>
        </Col>
      </Row>
    </Modal>
  );
}
