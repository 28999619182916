import { Layout, Row, Col, Spin } from "antd";
import imageLogo from "../assets/images/Encontros-da-Primavera-login.svg";

export default function Loading() {
  return (
    <div className="bg-[#24588D] w-screen h-screen absolute z-50">
      <div className="flex flex-col justify-center items-center w-1/2 h-1/2 absolute top-1/4 left-1/4">
        <img src={imageLogo} className="mb-4" style={{ maxWidth: 300 }} />
        <Spin spinning={true} style={{ color: "#FFF" }} />
      </div>
    </div>
  );
}
