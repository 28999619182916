import { Row, Col, Button, Divider, Avatar, Tooltip, Pagination, Spin, Popover, Empty, DatePicker, Select, Input, Form, Switch, message } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined, InboxOutlined, FilterOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { FiClock, FiCalendar, FiMapPin } from "react-icons/fi";

import config from "../utils/config.js";
import Create from "../components/Credits/Create.js";
import Update from "../components/Credits/Update.js";
import Delete from "../components/Credits/Delete.js";

import endpoints from "../utils/endpoints.js";
import { useForm } from "antd/es/form/Form.js";

import "react-quill/dist/quill.snow.css";
import CustomTable from "../components/Table.js";

function Credits() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.credits.read)
      .then((res) => {
        console.log(res.data);
        setData(res.data.credits);

        handlePrepareData(res.data.credits);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(arr) {
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < arr.length; i++) {
      auxArray.push({
        key: i,
        name: arr[i].name,
        img: <img src={`${config.server_ip}media/${arr[i].img}`} className="max-h-[100px]" />,
        text: <div dangerouslySetInnerHTML={{ __html: arr[i].text }}></div>,
        actions: (
          <div className="flex justify-end items-center">
            <Button onClick={() => handleOpenUpdate(arr[i])} type="primary" className="flex justify-center items-center mr-2">
              <EditOutlined />
            </Button>
            <Button danger onClick={() => handleOpenDelete(arr[i])} className="flex justify-center items-center">
              <DeleteOutlined />
            </Button>
          </div>
        ),
        full_data: data[i],
      });
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(aux) {
    setSelectedData(aux);
    setIsOpenUpdate(true);
  }

  function handleCloseUpddate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSubmit(values) {
    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "credits" },
      })
      .then((res) => {
        setIsLoading(false);
        message.success(values.is_active ? "Os créditos estão agora disponíveis" : "A aplicação deixou de ter os créditos disponíveis");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpddate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="dashboard-title-row">
        <Col span={4} className="flex justify-start items-center">
          <h3 className="dashboard-title">Créditos</h3>
        </Col>
        <Col span={20} className="flex justify-end items-center">
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item label="Ativo" name="is_active" className="mb-0 mr-4">
              <Switch size="large" onChange={form.submit} />
            </Form.Item>
          </Form>
          <Button size="large" className="flex justify-center items-center mr-2" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Imagem",
                  dataIndex: "img",
                  key: "img",
                },
                {
                  title: "Text",
                  dataIndex: "text",
                  key: "text",
                },
                {
                  title: "",
                  dataIndex: "actions",
                  key: "actions",
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Credits;
