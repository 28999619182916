const endpoints = {
  auth: {
    login: "auth/login",
    register: "auth/register",
    verifyUser: "auth/verifyUser",
    verifyToken: "auth/verifyToken",
    renewToken: "auth/renewToken",
  },
  info: {
    read: "info/read",
  },
  committee: {
    read: "committee/read",
    create: "committee/create",
    update: "committee/update",
    delete: "committee/delete",
  },
  credits: {
    read: "credits/read",
    create: "credits/create",
    update: "credits/update",
    delete: "credits/delete",
  },
  sponsor: {
    read: "sponsor/read",
    create: "sponsor/create",
    update: "sponsor/update",
    delete: "sponsor/delete",
    readType: "sponsor/readType",
    createType: "sponsor/createType",
    updateType: "sponsor/updateType",
    deleteType: "sponsor/deleteType",
  },
  sponsorBanners: {
    read: "sponsorBanner/read",
    create: "sponsorBanner/create",
    update: "sponsorBanner/update",
    delete: "sponsorBanner/delete",
  },
  poster: {
    read: "poster/read",
    create: "poster/create",
    update: "poster/update",
    delete: "poster/delete",
  },
  communication: {
    read: "communication/read",
    create: "communication/create",
    update: "communication/update",
    delete: "communication/delete",
  },
  program: {
    read: "program/read",
    readyById: "program/readById",
    create: "program/create",
    update: "program/update",
    delete: "program/delete",
    rate: "program/rate",
  },
  faculty: {
    read: "faculty/read",
    create: "faculty/create",
    update: "faculty/update",
    delete: "faculty/delete",
  },
  notification: {
    read: "notification/read",
    readById: "notification/readyById",
    create: "notification/create",
    send: "notification/send",
    update: "notification/update",
    delete: "notification/delete",
  },
  user: {
    create: "user/create",
    read: "user/read",
    readById: "user/readById",
    update: "user/update",
    updateBO: "user/updateBO",
    delete: "user/delete",
    readFileImport: "user/readFileImport",
    import: "user/import",
    changePhoto: "user/changePhoto",
  },
  messages: {
    read: "message/read",
    readUnreaded: "message/readUnreaded",
    readDetails: "message/readDetails",
    sendNotificationMessage: "message/sendNotificationMessage",
    create: "message/create",
    updateRead: "message/updateRead",
  },
  device: {
    readByToken: "device/readByToken",
    read: "device/read",
    create: "device/create",
  },
  welcomeLetter: {
    read: "welcomeLetter/read",
    update: "welcomeLetter/update",
  },
  settings: {
    read: "settings/read",
    readByName: "settings/readByName",
    update: "settings/update",
  },
  room: {
    read: "room/read",
    create: "room/create",
    update: "room/update",
    delete: "room/delete",
  },
  faq: {
    read: "faq/read",
  },
};

export default endpoints;
