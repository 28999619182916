import { Row, Col, Button, Spin, Divider, Pagination, Empty, Form, Input } from "antd";
import { EditOutlined, DeleteOutlined, InboxOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { IoPersonCircleOutline } from "react-icons/io5";

import config from "../utils/config";
import Create from "../components/Faculty/Create";
import Update from "../components/Faculty/Update";
import Delete from "../components/Faculty/Delete";

import Search from "antd/es/input/Search";
import endpoints from "../utils/endpoints";
import CustomTable from "../components/Table";

function Faculty() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.faculty.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let auxArray = [];
    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];

      let aux = {
        key: i,
        img: (
          <div
            className="min-w-2 min-h-12 bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: arrayData.img ? `url('${config.server_ip}media/${arrayData.img}')` : `url('${config.server_ip}media/User-default.svg')`,
            }}
          ></div>
        ),
        name: arrayData.name,
        specialty: arrayData.specialty,
        institution: arrayData.institution,
        actions: (
          <div className="flex justify-end items-center">
            <Button type="primary" className="flex justify-center items-center mr-2" onClick={() => handleOpenUpdate(arrayData)}>
              <EditOutlined className="edit-action" />
            </Button>
            <Button danger className="flex justify-center items-center" onClick={() => handleOpenDelete(arrayData)}>
              <DeleteOutlined className="delete-action" />
            </Button>
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetData();
  }

  function handleSearch(_, allFields) {
    let value = allFields.search;
    setSearchText(value);
    const newData = data.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

    handlePrepareData(newData);
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="dashboard-title-row">
        <Col span={4} className="flex justify-start items-center">
          <h3 className="dashboard-title">Faculty</h3>
        </Col>
        <Col span={20} className="flex justify-end items-center">
          <Button size="large" className="flex justify-center items-center mr-2" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                {
                  title: "",
                  dataIndex: "img",
                  key: "img",
                },
                {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                  sort: true,
                  sortType: "text",
                  search: "name",
                },
                {
                  title: "Especialidade",
                  dataIndex: "specialty",
                  key: "specialty",
                },
                {
                  title: "Instituição",
                  dataIndex: "institution",
                  key: "institution",
                },
                {
                  title: "",
                  dataIndex: "actions",
                  key: "actions",
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Faculty;
