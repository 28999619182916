import { Row, Col, Button, Divider, Avatar, Tooltip, Pagination, Spin, Popover, Empty, DatePicker, Select, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined, InboxOutlined, FilterOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { FiClock, FiCalendar, FiMapPin } from "react-icons/fi";

import config from "../utils/config.js";
import Create from "../components/Program/Create.js";
import Update from "../components/Program/Update.js";
import Delete from "../components/Program/Delete.js";

import endpoints from "../utils/endpoints.js";
import { useForm } from "antd/es/form/Form.js";

import "react-quill/dist/quill.snow.css";
import CustomTable from "../components/Table.js";

function Program() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [faculty, setFaculty] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.program.read)
      .then((response) => {
        console.log(response.data);
        setData(response.data.program);
        setFaculty(response.data.faculty);
        setRooms(response.data.room);

        handlePrepareData(response.data.program, response.data.faculty);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data, facultyData) {
    console.log(data);
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < data.length; i++) {
      let mods = [];
      let speakers = [];
      let modsArray = JSON.parse(data[i].mods);
      let speakersArray = JSON.parse(data[i].speakers);

      if (modsArray !== null) {
        for (let z = 0; z < modsArray.length; z++) {
          let modOfProgram = facultyData.find((x) => x.id === modsArray[z]);
          mods.push(modOfProgram);
        }
      }

      if (speakersArray !== null) {
        for (let z = 0; z < speakersArray.length; z++) {
          let speakerOfProgram = facultyData.find((x) => x.id === speakersArray[z]);
          speakers.push(speakerOfProgram);
        }
      }

      let aux = {
        key: i,
        title: <span dangerouslySetInnerHTML={{ __html: data[i].title }}></span>,
        id_room: data[i].id_room,
        room_name: data[i].room_name,
        mods: (
          <Avatar.Group>
            {mods.map((item) => (
              <Tooltip title={item.name} placement="top">
                {item.img ? <Avatar src={`${config.server_ip}media/${item.img}`} /> : <Avatar className="empty" icon={<UserOutlined />} />}
              </Tooltip>
            ))}
          </Avatar.Group>
        ),
        speakers: (
          <Avatar.Group>
            {speakers.map((item) => (
              <Tooltip title={item.name} placement="top">
                {item.img ? <Avatar src={`${config.server_ip}media/${item.img}`} /> : <Avatar className="empty" icon={<UserOutlined />} />}
              </Tooltip>
            ))}
          </Avatar.Group>
        ),
        date: dayjs(data[i].date).format("YYYY-MM-DD"),
        hour_start: `${data[i].hour_start.split(":")[0]}:${data[i].hour_start.split(":")[1]}`,
        hour_end: `${data[i].hour_end.split(":")[0]}:${data[i].hour_end.split(":")[1]}`,
        actions: (
          <div className="flex justify-end items-center">
            <Button onClick={() => handleOpenUpdate(data[i])} type="primary" className="flex justify-center items-center mr-2">
              <EditOutlined />
            </Button>
            <Button danger onClick={() => handleOpenDelete(data[i])} className="flex justify-center items-center">
              <DeleteOutlined />
            </Button>
          </div>
        ),
        full_data: data[i],
      };

      auxArray.push(aux);
    }
    console.log(auxArray);

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    if (data) {
      let objAux = {
        ...data,
        mods: data.mods ? JSON.parse(data.mods) : null,
        speakers: data.speakers ? JSON.parse(data.speakers) : null,
        date: dayjs(data.date),
        duration: [dayjs(data.hour_start, "HH:mm:ss"), dayjs(data.hour_end, "HH:mm:ss")],
      };

      setSelectedData(objAux);
      setIsOpenUpdate(true);
    }
  }

  function handleCloseUpddate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} faculty={faculty} rooms={rooms} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpddate} faculty={faculty} rooms={rooms} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="dashboard-title-row">
        <Col span={4} className="flex justify-start items-center">
          <h3 className="dashboard-title">Programa</h3>
        </Col>
        <Col span={20} className="flex justify-end items-center">
          <Button size="large" className="flex justify-center items-center mr-2" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                {
                  title: "Título",
                  dataIndex: "title",
                  key: "title",
                },
                {
                  title: "Sala",
                  dataIndex: "room_name",
                  key: "id_room",
                  filters: rooms.map((item) => ({
                    text: item.name,
                    value: item.id,
                  })),
                },
                {
                  title: "Data",
                  dataIndex: "date",
                  key: "date",
                  width: 300,
                  sort: true,
                  sortType: "text",
                  search: "date",
                  searchType: "datepicker",
                },
                {
                  title: "Hora ínicio",
                  dataIndex: "hour_start",
                  key: "hour_start",
                },
                {
                  title: "Hora fim",
                  dataIndex: "hour_end",
                  key: "hour_end",
                },
                {
                  title: "Moderadores",
                  dataIndex: "mods",
                  key: "mods",
                },
                {
                  title: "Palestrantes",
                  dataIndex: "speakers",
                  key: "speakers",
                },
                {
                  title: "",
                  dataIndex: "actions",
                  key: "actions",
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Program;
