import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Input, Form, Avatar, Divider, Spin } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import Update from "../components/User/Update";
import Create from "../components/User/Create";
import Import from "../components/User/Import";
import Delete from "../components/User/Delete";
import endpoints from "../utils/endpoints";
import DrawerExportTable from "../components/Export/DrawerExportTable";

import config from "../utils/config";
import CustomTable from "../components/Table";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "",
      dataIndex: "img",
      width: 40,
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Profissão",
      dataIndex: "job_title",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetUsers();
  }, []);

  function handleGetUsers() {
    setIsLoading(true);
    axios
      .get(endpoints.user.read)
      .then((res) => {
        setUsers(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        img: <Avatar src={`${config.server_ip}media/${data[i].img ? data[i].img : "User-default.svg"}`} />,
        name: data[i].name,
        email: data[i].email,
        job_title: data[i].job_title,
        actions: (
          <div className="flex justify-end items-center">
            <Button type="primary" className="flex justify-center items-center" onClick={() => handleOpenUpdate(data[i])}>
              <EditOutlined />
            </Button>
            {!data[i].is_admin && (
              <Button danger className="flex justify-center items-center ml-2" onClick={() => handleOpenDelete(data[i])}>
                <DeleteOutlined />
              </Button>
            )}
          </div>
        ),
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetUsers();
  }

  function handleOpenUpdate(data) {
    setSelectedUser(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetUsers();
  }

  function handleOpenDelete(data) {
    setSelectedUser(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedUser({});
    setIsOpenDelete(false);
    handleGetUsers();
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = users.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData);
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  return (
    <div className="dashboard-page">
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedUser} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedUser} />
        <DrawerExportTable open={isOpenExportDrawer} close={handleCloseExport} data={tableData} />
        <Col span={24}>
          <Row className="dashboard-title-row">
            <Col span={12} className="flex justify-start items-center">
              <h3 className="dashboard-title">Utilizadores</h3>
            </Col>
            <Col span={12}>
              <div className="flex justify-end items-center">
                <Button className="mr-2" size="large" onClick={handleGetUsers}>
                  <ReloadOutlined />
                </Button>
                <Button type="primary" size="large" onClick={handleOpenCreate}>
                  Adicionar
                </Button>
              </div>
            </Col>
          </Row>
          <Divider />
          <Spin spinning={isLoading}>
            <Row className="dashboard-content-row">
              <Col span={24}>
                <CustomTable loading={isLoading} columns={columns} dataSource={tableData} />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default Users;
