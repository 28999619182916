import { Avatar, Button, Col, DatePicker, Divider, Empty, Form, Input, Row, Select, Space, Spin, Table, Tag, Tooltip } from "antd";

import React, { useRef, useState } from "react";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";

const CustomTable = (props) => {
  console.log(props);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex, dataIndexType, dataKey) =>
    dataIndex
      ? {
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
              style={{
                padding: 10,
                position: "relative",
              }}
              onKeyDown={(e) => e.stopPropagation()}
            >
              {dataIndexType === "datepicker" ? (
                <DatePicker
                  size="large"
                  onChange={(e) => setSelectedKeys(e ? [e.format("YYYY-MM-DD")] : [])}
                  style={{
                    marginBottom: 8,
                    display: "block",
                  }}
                />
              ) : (
                <Input
                  size="large"
                  ref={searchInput}
                  placeholder={`Procurar...`}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{
                    marginBottom: 8,
                    display: "block",
                  }}
                />
              )}
              <Space>
                <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />}>
                  Search
                </Button>
                <Button
                  onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                  style={{
                    width: 90,
                  }}
                >
                  Reset
                </Button>
              </Space>
            </div>
          ),
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? "#1677ff" : undefined,
              }}
            />
          ),
          onFilter: (value, record) => record[dataKey].toString().toLowerCase().includes(value.toLowerCase()),
          filterDropdownProps: {
            onOpenChange(open) {
              if (open) {
                setTimeout(() => searchInput.current?.select(), 100);
              }
            },
          },

          render: (text) =>
            searchedColumn === dataIndex ? (
              <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ""} />
            ) : (
              text
            ),
        }
      : null;

  return (
    <Table
      onChange={props.onChange}
      className={props.className}
      dataSource={props.dataSource}
      pagination={{ position: ["none", "bottomCenter"] }}
      scroll={{ x: 50 }}
      columns={props.columns.map((item) => ({
        title: item.title,
        dataIndex: item.dataIndex,
        key: item.key,
        width: item.width,
        filters: item.filters,
        onFilter: (value, record) => {
          console.log(record);
          return record.full_data ? record.full_data[item.key] === value : record[item.key] === value;
        },
        sorter:
          item.sort && (item.sortType === "text" || item.sortType === "number")
            ? (a, b) => {
                if (item.sortType === "text") return a[item.dataIndex].localeCompare(b[item.dataIndex]);
                else return a[item.dataIndex] - b[item.dataIndex];
              }
            : null,
        ...getColumnSearchProps(item.search, item.searchType, item.key),
      }))}
    />
  );
};

export default CustomTable;
