import { Button, Input, Form, notification, Drawer, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";
import ReactQuill from "react-quill";

const { Dragger } = Upload;

export default function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState(null);

  const [form] = Form.useForm();

  const uploadProps = {
    accept: "image/png, image/jpeg",
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    if (data.img) {
      setFileList([{ uid: "not_new", name: data.img }]);
      setFilePreview(data.img);
      data.img = [{ uid: "not_new", name: data.img }];
    }

    form.setFieldsValue(data);
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        if (file.uid !== "not_new") {
          formData.append("file", file);
        } else {
          values.img = file.name;
        }
      });
    } else {
      values.img = null;
    }

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.credits.update}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        close();
        setFilePreview("");
        setFileList([]);
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Crédito foi editado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  function handleCloseDrawer() {
    close();
    form.resetFields();
    setFileList([]);
    setFilePreview(null);
  }

  return (
    <Drawer
      width={800}
      title={`Editar crédito`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Guardar
        </Button>,
        <Button size="large" onClick={handleCloseDrawer}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="update-faculty" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile}>
          <Dragger {...uploadProps} className={`dragger ${filePreview ? "has-preview" : ""}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview-file ${filePreview ? "has-preview" : ""}`}
              style={{
                backgroundImage: `url('${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}media/${filePreview}`}')`,
              }}
            ></div>
          </Dragger>
        </Form.Item>

        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>

        <Form.Item name="text" label="Texto" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <ReactQuill placeholder="Texto..." className="description_react_quill" theme="snow" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
