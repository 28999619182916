import { Row, Col, Divider, Spin } from "antd";
import { AndroidOutlined, AppleOutlined, UserOutlined, MessageOutlined, QuestionCircleOutlined, TeamOutlined, NotificationOutlined, HomeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import endpoints from "../utils/endpoints";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({});

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.info.read)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <div className="bg-white rounded-[8px]">
          <Row className="p-[20px]">
            <Col span={12} className="d-flex jc-start ai-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">Dispositivos</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={12} className="flex flex-col justify-center items-center">
                <AppleOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.devices ? data.devices.filter((item) => item.platform === "ios")?.length : 0}</p>
                <p>iOS</p>
              </Col>
              <Col span={12} className="flex flex-col justify-center items-center">
                <AndroidOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">
                  {data.devices?.filter((item) => item.platform === "android") ? data.devices?.filter((item) => item.platform === "android").length : 0}
                </p>
                <p>Android</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
      <Col span={8}>
        <div className="bg-white h-full rounded-[8px]">
          <Row className="p-[20px]">
            <Col span={12} className="flex justify-start items-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">Utilizadores</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={24} className="flex flex-col justify-center items-center">
                <UserOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.users ? data.users.length : 0}</p>
                <p>Nº total de utilizadores</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
      <Col span={8}>
        <div className="bg-white h-full" style={{ borderRadius: 8 }}>
          <Row className="p-[20px]">
            <Col span={12} className="flex justify-start items-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">Mensagens</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={24} className="flex flex-col justify-center items-center">
                <MessageOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.messages ? data.messages.length : 0}</p>
                <p>Nº total de mensagens</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-full" style={{ borderRadius: 8 }}>
          <Row className="p-[20px]">
            <Col span={12} className="flex justify-start items-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">Faculty</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={24} className="flex flex-col justify-center items-center">
                <TeamOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.faculty ? data.faculty.length : 0}</p>
                <p>Nº total de faculty</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-full" style={{ borderRadius: 8 }}>
          <Row className="p-[20px]">
            <Col span={12} className="flex justify-start items-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">Notificações</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={24} className="flex flex-col justify-center items-center">
                <NotificationOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.notifications ? data.notifications.length : 0}</p>
                <p>Nº total de notificações</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-full" style={{ borderRadius: 8 }}>
          <Row className="p-[20px]">
            <Col span={12} className="flex justify-start items-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">Salas</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={24} className="flex flex-col justify-center items-center">
                <HomeOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.room ? data.room.length : 0}</p>
                <p>Nº total de salas</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>

      <Col span={6}>
        <div className="bg-white h-full" style={{ borderRadius: 8 }}>
          <Row className="p-[20px]">
            <Col span={12} className="flex justify-start items-center">
              <p className="mt-0 mb-0 text-[20px] font-bold">FAQs</p>
            </Col>
          </Row>
          <Divider className="mb-0 mt-0" />
          <Spin spinning={isLoading}>
            <Row className="p-[20px]">
              <Col span={24} className="flex flex-col justify-center items-center">
                <QuestionCircleOutlined className="text-[30px]" />
                <p className="text-[30px] font-bold text-center mb-0 mt-2">{data.faqs ? data.faqs.length : 0}</p>
                <p>Nº total de faqs</p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
    </Row>
  );
}

export default Dashboard;
