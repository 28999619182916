import { Row, Col, Button, Divider, Avatar, Tooltip, Pagination, Spin, Popover, Empty, DatePicker, Select, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined, InboxOutlined, FilterOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { FiClock, FiCalendar, FiMapPin } from "react-icons/fi";

import Table from "../components/Table.js";
import Create from "../components/Room/Create.js";
import Update from "../components/Room/Update.js";
import Delete from "../components/Room/Delete.js";

import endpoints from "../utils/endpoints.js";
import { useForm } from "antd/es/form/Form.js";

import "react-quill/dist/quill.snow.css";

function Room() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.room.read)
      .then((res) => {
        setData(res.data);

        handlePrepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(arr) {
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < arr.length; i++) {
      let aux = {
        key: i,
        name: arr[i].name,
        color: (
          <div className={`flex p-1 text-white`} style={{ background: arr[i].color }}>
            {arr[i].color}
          </div>
        ),
        actions: (
          <div className="flex justify-end items-center">
            <Button className="flex items-center justify-center mr-2" type="primary" onClick={() => handleOpenUpdate(arr[i])}>
              <EditOutlined />
            </Button>
            <Button className="flex items-center justify-center" danger onClick={() => handleOpenDelete(arr[i])}>
              <DeleteOutlined />
            </Button>
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpddate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpddate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="dashboard-title-row">
        <Col span={4} className="flex justify-start items-center">
          <h3 className="dashboard-title">Salas</h3>
        </Col>
        <Col span={20} className="flex justify-end items-center">
          <Button size="large" className="mr-2 flex justify-center items-center" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <Table
              dataSource={tableData}
              columns={[
                {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Cor",
                  dataIndex: "color",
                  key: "color",
                },
                {
                  title: "",
                  dataIndex: "actions",
                  key: "actions",
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Room;
