import { Breadcrumb, Layout, Menu, theme, Row, Col, Button } from "antd";
import { useState, useEffect } from "react";
import { Outlet, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  FileOutlined,
  FileTextOutlined,
  LogoutOutlined,
  NotificationOutlined,
  MailOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axios from "axios";
import config from "../utils/config";
import Loading from "./Loading";
import logo from "../assets/images/Encontros-da-Primavera-login.svg";

import endpoints from "../utils/endpoints";

const { Content, Sider, Header } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    //icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("DADOS", "g1", null, [getItem("DASHBOARD", "dashboard")], "group"),
  getItem(
    "EVENTO",
    "g2",
    null,
    [getItem("PROGRAMA", "program"), getItem("SALAS", "room"), getItem("FACULTY", "faculty"), getItem("COMMITTES", "committees"), getItem("FAQs", "faq")],
    "group"
  ),
  getItem("EXPOSIÇÃO TÉCNICA", "g2", null, [getItem("PATROCINADORES", "sponsors"), getItem("TIPOS", "sponsors-type"), getItem("MERCK", "sponsor-banners")], "group"),
  getItem(
    "CONFIGURAÇÕES",
    "g3",
    null,
    [
      getItem("CARTA BOAS-VINDAS", "welcome-letter"),
      getItem("CRÉDITOS", "credits"),
      getItem("NOTIFICAÇÕES", "notifications"),
      getItem("UTILIZADORES", "users"),
      getItem("DEFINIÇÕES", "settings"),
    ],
    "group"
  ),
];

const MainDashboard = ({ handleLogout }) => {
  const location = useLocation();
  let pathname = location.pathname.split("/");

  const [user, setuser] = useState({});
  const [current, setCurrent] = useState(pathname[pathname.length - 1] === "" ? "/" : `/${pathname[pathname.length - 1]}`);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(endpoints.auth.verifyToken, {
          data: tokenData,
        })
        .then((res) => {
          if (res.data.token_valid) {
            setuser(res.data.token_decoded);
            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          } else {
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  function handleClickMenu(e) {
    if (e.key === "logout") {
      handleLogout();
    } else {
      navigate(e.key);
    }
  }

  return (
    <div>
      {isLoading && <Loading />}
      <Layout>
        <Sider width={250} className="flex flex-col">
          <div className="flex flex-col items-center justify-center w-full max-h-20 p-3" onClick={() => navigate("/")}>
            <img src={logo} className="w-full object-contain min-h-0" />
          </div>
          <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: "#001529" }}>
            <Row className="h-100">
              <Col span={12}></Col>
              <Col span={12} className="flex justify-end items-center">
                <div className="text-white mr-5">
                  <UserOutlined className="mr-2" />
                  {user.name}
                </div>
                <div>
                  <Button className="text-white" type="text" icon={<LogoutOutlined />} onClick={handleLogout}>
                    Terminar Sessão
                  </Button>
                </div>
              </Col>
            </Row>
          </Header>
          <Content className="admin-layout-content">
            <Outlet context={user} />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default MainDashboard;
