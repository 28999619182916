import { Row, Col, Button, Divider, Spin, Empty } from "antd";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../utils/config";
import Create from "../components/SponsorType/Create";
import Update from "../components/SponsorType/Update";

import endpoints from "../utils/endpoints";
import CustomTable from "../components/Table";

function SponsorsType() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.sponsor.readType)
      .then((res) => {
        let auxData = [];
        for (let i = 0; i < res.data.length; i++) {
          let arrayData = res.data[i];
          let aux = {
            key: i,
            number: i + 1,
            name: arrayData.name,
            actions: (
              <div className="flex justify-end items-center">
                <Button type="primary" className="flex justify-center items-center" onClick={() => handleOpenUpdate(arrayData)}>
                  <EditOutlined />
                </Button>
              </div>
            ),
          };

          auxData.push(aux);
        }

        setData(res.data);
        setTableData(auxData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedData({});
    setIsOpenUpdate(false);
    handleGetData();
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Row className="dashboard-title-row">
        <Col span={12} className="flex justify-start items-center">
          <h3 className="dashboard-title">Tipos de patrocinador</h3>
        </Col>
        <Col span={12} className="flex justify-end items-center">
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                { title: "#", dataIndex: "number", key: "number", width: 100 },
                { title: "Nome", dataIndex: "name", key: "name" },
                { title: "", dataIndex: "actions", key: "actions" },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default SponsorsType;
