import { Row, Col, Button, Form, notification, DatePicker, Switch, message, Input } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

import endpoints from "../../utils/endpoints.js";
import { DeleteOutlined } from "@ant-design/icons";

function SettingsPosters({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let auxData = data.filter((item) => item.name === "posters")[0];
      auxData.json = JSON.parse(auxData.json);
      form.setFieldsValue(auxData.json);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    values.date = dayjs(values.date).format("YYYY-MM-DD HH:mm:ss");
    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "countdown" },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        message.success("Definições foram editadas com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  return (
    <div className="dashboard-page">
      <Row className="dashboard-content-row">
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields} initialValues={{ event_days: [{}] }}>
            <Form.Item name="url" label="URL" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <Input size="large" placeholder="https://google.pt" />
            </Form.Item>
            <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
              Guardar
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsPosters;
