import React, { Component, useState } from "react";
import { Layout, Button, Row, Col, Typography, Form, Input, Switch, notification, message } from "antd";
import { RadiusBottomleftOutlined, RadiusBottomrightOutlined, RadiusUpleftOutlined, RadiusUprightOutlined } from "@ant-design/icons";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../layout/Loading";
import endpoints from "../utils/endpoints";

import "../assets/styles/login.css";

const { Title } = Typography;
const { Content } = Layout;

export default function SignIn() {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      axios
        .post(endpoints.auth.verifyToken, {
          data: token,
        })
        .then((res) => {
          console.log(res);
          if (res.data.token_valid) {
            navigate("/dashboard");
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  function onFinish(values) {
    setIsButtonLoading(true);
    setIsButtonLoading(true);
    axios
      .post(endpoints.auth.login, {
        data: values,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.user) {
          let userData = res.data.user;
          if (userData.is_admin) {
            localStorage.setItem("token", res.data.token);
            message.success("Login efetuado com sucesso!");
            setTimeout(() => {
              setIsButtonLoading(false);
              navigate("/dashboard");
            }, 1000);
          } else {
            setIsButtonLoading(false);
            message.error("Não tem permissões para entrar no backoffice");
          }
        } else {
          setIsButtonLoading(false);
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
        message.error("Alguma coisa de errado aconteceu, tente novamente mais tarde!");
      });
  }

  function onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
    message.error("Por favor verifique se preencheu todos os campos necessários!");
  }

  return (
    <>
      {isLoading && <Loading />}
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} className="justify-between flex login-form-row">
            <Col xs={{ span: 24 }} lg={{ span: 16 }} md={{ span: 16 }} className="form-col">
              <p className="mb-15 text-[24px] font-bold text-center">Login</p>
              <p className="mt-2 text-base text-center">Insira o seu e-mail e password para fazer login</p>
              <Form onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical" className="mt-4">
                <Form.Item
                  className="username"
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira o seu e-mail!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="E-mail" />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor insira a sua password!",
                    },
                  ]}
                >
                  <Input size="large" type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item className="button-signin mt-4">
                  <Button type="primary" htmlType="submit" style={{ width: "100%" }} size="large" loading={isButtonLoading}>
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
