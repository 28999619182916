import { Row, Col, Button, Spin, Divider, Pagination, Empty, Form, Input, Select, Avatar } from "antd";
import { EditOutlined, DeleteOutlined, InboxOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import endpoints from "../utils/endpoints";
import Create from "../components/Committees/Create";
import Update from "../components/Committees/Update";
import Delete from "../components/Committees/Delete";

import Search from "antd/es/input/Search";
import config from "../utils/config";
import CustomTable from "../components/Table";

function Committees() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [commissionPosition, setCommissionPosition] = useState([]);
  const [commissionType, setCommissionType] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.committee.read)
      .then((res) => {
        setData(res.data);

        handlePrepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(items) {
    setIsLoading(true);
    let commissionData = [];

    for (let i = 0; i < items.length; i++) {
      let arrayData = items[i];

      let aux = {
        key: i,
        id: arrayData.id,
        img: <Avatar src={`${config.server_ip}media/${arrayData.img ? arrayData.img : "User-default.svg"}`} />,
        name: arrayData.name,
        work: arrayData.work,
        type: arrayData.type,
        actions: (
          <div className="flex justify-end items-center">
            <Button type="primary" className="flex justify-center items-center mr-2" onClick={() => handleOpenUpdate(arrayData)}>
              <EditOutlined className="edit-action" />
            </Button>
            <Button danger className="flex justify-center items-center" onClick={() => handleOpenDelete(arrayData)}>
              <DeleteOutlined className="delete-action" />
            </Button>
          </div>
        ),
      };

      commissionData.push(aux);
    }

    setTableData(commissionData);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedData({});
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <div className="dashboard-page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="dashboard-title-row">
        <Col span={4} className="flex justify-start items-center">
          <h3 className="dashboard-title">Comissão</h3>
        </Col>
        <Col span={20} className="flex justify-end items-center">
          <Button size="large" className="flex justify-center items-center mr-2" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                {
                  title: "",
                  dataIndex: "img",
                  key: "img",
                },
                {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                  sort: true,
                  sortType: "text",
                  search: "name",
                },
                {
                  title: "Local de trabalho / Afiliação",
                  dataIndex: "work",
                  key: "work",
                },
                {
                  title: "Tipo",
                  dataIndex: "type",
                  key: "type",
                },
                {
                  title: "",
                  dataIndex: "actions",
                  key: "actions",
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Committees;
