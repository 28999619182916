import { Row, Col, Modal, Button, Input, Form, notification, message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config.js";

import { DeleteOutlined } from "@ant-design/icons";
import endpoints from "../../utils/endpoints.js";

function ModalDeleteProgram({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleDelete() {
    setIsButtonLoading(true);

    axios
      .post(endpoints.program.delete, {
        data: { id: data.id },
      })
      .then((resData) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Programa foi apagado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-program"
      title={`Apagar programa`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button danger icon={<DeleteOutlined />} size="large" type="primary" onClick={handleDelete} loading={isButtonLoading} disabled={isButtonLoading}>
          Apagar
        </Button>,
        <Button size="large" onClick={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <p>Tem a certeza que quer apagar o programa:</p>
          <div className="mt-2" dangerouslySetInnerHTML={{ __html: data.title }}></div>
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalDeleteProgram;
