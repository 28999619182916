import { Row, Col, Button, Input, DatePicker, TimePicker, Select, Radio, Form, notification, Drawer, Switch, message, Divider } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import config from "../../utils/config.js";

import "react-quill/dist/quill.snow.css";
import { DeleteOutlined, PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import CreateFacuty from "../Faculty/Create.js";
import endpoints from "../../utils/endpoints.js";

const { Option } = Select;
const { RangePicker } = TimePicker;

function Create({ open, close, faculty, rooms }) {
  const [eventDays, setEventDays] = useState([]);
  const [eventRooms, setEventRooms] = useState([]);
  const [facultyData, setFacultyData] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenCreateFaculty, setIsOpenCreateFaculty] = useState(false);

  const defaultFormList = {
    description: [{ themes: [], speakers: [] }],
    mods: [],
    add_to_calendar: true,
    has_description: true,
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      setFacultyData(faculty);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let speakersAux = [];
    if (values.has_description) {
      let auxDescription = values.description;
      for (let i = 0; i < auxDescription.length; i++) {
        let auxSpeakersArray = auxDescription[i].speakers;
        if (auxSpeakersArray) {
          for (let z = 0; z < auxSpeakersArray.length; z++) {
            if (!speakersAux.includes(auxSpeakersArray[z])) {
              speakersAux.push(auxSpeakersArray[z]);
            }
          }
        } else {
          auxDescription[i].speakers = [];
        }
      }
    }

    let obj = {
      ...values,
      description: JSON.stringify(values.description),
      date: dayjs(values.date).format("YYYY-MM-DD"),
      hour_start: dayjs(values.duration[0]).format("HH:mm:ss"),
      hour_end: dayjs(values.duration[1]).format("HH:mm:ss"),
      speakers: speakersAux,
      hide_local: values.hide_local ? 1 : 0,
      add_to_calendar: values.add_to_calendar ? 1 : 0,
      aha_slides: values.aha_slides ? 1 : 0,
      aha_slides_active: values.aha_slides_active ? 1 : 0,
    };

    delete obj.duration;

    axios
      .post(endpoints.program.create, {
        data: obj,
      })
      .then((res) => {
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
          form.resetFields();
          message.success("Sessão foi adicionada com sucesso!");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleClose() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  return (
    <Drawer
      width={800}
      title={`Adicionar sessão`}
      open={open}
      onClose={handleClose}
      maskClosable={false}
      extra={[
        <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={handleClose}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-program-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields} initialValues={defaultFormList}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item name="title" label="Título" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <ReactQuill placeholder="Título" className="title-react-quill" theme="snow" />
            </Form.Item>
            <Form.Item name="mods" label="Moderadores">
              <Select
                showSearch
                allowClear
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                placeholder="Selecione..."
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={facultyData.map((value, index) => ({
                  label: value.name,
                  value: value.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="has_description" label="Contém temas ou palestrantes?" valuePropName="checked">
              <Switch defaultChecked size="large" />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.has_description !== currentValues.has_description}>
              {({ getFieldValue }) => {
                let has_description = getFieldValue("has_description");
                if (has_description) {
                  return (
                    <Form.List name="description" label="Descrição">
                      {(fields, { add, remove }) => (
                        <Row gutter={[24]}>
                          <Col span={24}>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Row>
                                <Col span={24} className={`${fields.length > 1 && index > 0 ? "mt-2" : ""}`}>
                                  <Row className={`mb-30 ${has_description ? "" : "disabled"}`}>
                                    <Col span={24}>
                                      <Form.Item {...restField} label="Temas" name={[name, "themes"]}>
                                        <ReactQuill readOnly={!has_description} placeholder="Tema..." className="description_react_quill" theme="snow" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                      <Form.Item className="mb-0" label="Palestrantes" name={[name, "speakers"]}>
                                        <Select
                                          disabled={!has_description}
                                          showSearch
                                          allowClear
                                          key={"palestrantes"}
                                          mode="multiple"
                                          size="large"
                                          style={{ width: "100%" }}
                                          placeholder="Selecione..."
                                          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                          options={facultyData.map((value, index) => ({
                                            label: value.name,
                                            value: value.id,
                                          }))}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {fields.length > 1 && (
                                      <Col span={24} className="flex justify-center items-center mt-4">
                                        <Button className="w-full" size="large" onClick={() => remove(name)} icon={<DeleteOutlined />}>
                                          Apagar tema
                                        </Button>
                                      </Col>
                                    )}
                                    <Col span={24} className="mt-4">
                                      <Divider type="dashed" />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                          </Col>

                          <Col span={24} className="flex justify-center items-center mt-8 mb-4">
                            <Button disabled={!has_description} size="large" onClick={() => add()} icon={<PlusCircleOutlined />}>
                              Adicionar tema
                            </Button>
                          </Col>

                          <Col span={24} className="mb-8 flex justify-center items-center">
                            <Divider className="mt-2 mb-2" />
                          </Col>
                        </Row>
                      )}
                    </Form.List>
                  );
                }
              }}
            </Form.Item>
            <Form.Item name="id_room" label="Sala" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <Select
                showSearch
                allowClear
                size="large"
                style={{ width: "100%" }}
                placeholder="Selecione..."
                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                options={rooms.map((item, index) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item name="date" label="Data" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
                  <DatePicker allowClear size="large" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="duration" label="Duração" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
                  <RangePicker size="large" format={"HH:mm"} key={"hora"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="hide_local" label="Esconder sala" valuePropName="checked" tooltip="Esconder sala da página do programa">
              <Switch size="large" />
            </Form.Item>
            <Form.Item name="add_to_calendar" label="Adicionar ao calendário?" valuePropName="checked" tooltip="Permitir que o utilizador adicione ao calendário">
              <Switch defaultChecked size="large" />
            </Form.Item>

            <Row gutter={[24]}>
              <Col span={6}>
                <Form.Item name="aha_slides" label="AHA Slides" valuePropName="checked" tooltip="Adicionar link de AHA Slides a este elemento do programa">
                  <Switch size="large" />
                </Form.Item>
              </Col>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.aha_slides !== currentValues.aha_slides}>
                {({ getFieldValue }) => {
                  if (getFieldValue("aha_slides")) {
                    return (
                      <Col span={6}>
                        <Form.Item name="aha_slides_active" label="AHA Slides Ativo" valuePropName="checked" tooltip="Ativar botão de AHA Slides para o utilizador">
                          <Switch disabled={!getFieldValue("aha_slides")} size="large" />
                        </Form.Item>
                      </Col>
                    );
                  }
                }}
              </Form.Item>
            </Row>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.aha_slides !== currentValues.aha_slides}>
              {({ getFieldValue }) => {
                if (getFieldValue("aha_slides")) {
                  return (
                    <Row gutter={[24]}>
                      <Col span={12}>
                        <Form.Item name="button_name" label="AHA Slides botão" tooltip="Nome do botão para o AHA Slides">
                          <Input disabled={!getFieldValue("aha_slides")} size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="button_link" label="AHA Slides link" tooltip="Link do botão para o AHA Slides">
                          <Input disabled={!getFieldValue("aha_slides")} size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Create;
