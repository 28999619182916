import { Row, Col, Button, Input, Form, notification, DatePicker, ColorPicker, message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";
import dayjs from "dayjs";

function SettingsDays({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let auxData = data.filter((item) => item.name === "days")[0];
      let auxFormData = JSON.parse(auxData.json);
      for (let i = 0; i < auxFormData.length; i++) {
        auxFormData[i].day = dayjs(auxFormData[i].day);
      }

      form.setFieldValue("days", auxFormData);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    console.log(values.days);
    let newArr = [];
    for (let i = 0; i < values.days.length; i++) {
      newArr.push({ ...values.days[i], day: dayjs(values.days[i].day).format("YYYY-MM-DD") });
    }

    axios
      .post(endpoints.settings.update, {
        data: { values: newArr, name: "days" },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        message.success("Definições foram editadas com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  return (
    <div className="dashboard-page">
      <Row className="dashboard-content-row">
        <Col span={24}>
          <Form form={form} id="settings-days" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
            <Form.List name="days">
              {(fields, { add, remove }) => (
                <Row gutter={[24]}>
                  <Col span={24}>
                    <Row>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Col span={8}>
                          <Form.Item label="Dia" name={[name, "day"]}>
                            <DatePicker size="large" />
                          </Form.Item>
                          <Form.Item
                            name={[name, "color"]}
                            label="Cor"
                            getValueFromEvent={(color) => {
                              return "#" + color.toHex();
                            }}
                          >
                            <ColorPicker format="hex" size="large" />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Button size="large" onClick={() => add()}>
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.List>

            <Row className="mt-2">
              <Col span={24}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsDays;
